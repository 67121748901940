import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class LoadjsonService {
  ln: string;
  langs: Array<string> = new Array();
  private changeLanguageSubject = new BehaviorSubject<string>('');

  constructor(private http: HttpClient, private translocoService: TranslocoService) {
    if (window?.navigator?.language?.startsWith('es')) {
      this.ln = 'es-ES';
    } else {
      this.ln = 'en';
    }
    if (this.ln.startsWith('es')) this.langs = ['en', 'es-ES'];
    else this.langs = ['es-ES', 'en'];
    this.translocoService.setActiveLang(this.ln.substring(0, 2));
    this.changeLanguageSubject.next(this.ln);
  }

  get changeLanguageGet(): Observable<string> {
    return this.changeLanguageSubject.asObservable();
  }

  changeLanguage(lang: string): void {
    this.ln = lang;
    this.translocoService.setActiveLang(this.ln.substring(0, 2));
    this.changeLanguageSubject.next(this.ln);
  }

  loadActivate() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/activate/en.json');
    } else {
      return this.http.get('/assets/i18n/activate/es.json');
    }
  }

  loadHome() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/home/en.json');
    } else {
      return this.http.get('/assets/i18n/home/es.json');
    }
  }

  // == ABOUT , POLITYCS, TERMS
  loadPolitycs() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/politycs/en.json');
    } else {
      return this.http.get('/assets/i18n/politycs/es.json');
    }
  }

  loadBuy() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/buy/en.json');
    } else {
      return this.http.get('/assets/i18n/buy/es.json');
    }
  }

  loadCompras() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/compras/en.json');
    } else {
      return this.http.get('/assets/i18n/compras/es.json');
    }
  }

  loadResources() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/recursos/en.json');
    } else {
      return this.http.get('/assets/i18n/recursos/es.json');
    }
  }

  loadAgenda() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/agenda/en.json');
    } else {
      return this.http.get('/assets/i18n/agenda/es.json');
    }
  }

  loadAuth() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/auth/en.json');
    } else {
      return this.http.get('/assets/i18n/auth/es.json');
    }
  }
  loadServicesEdit() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/services/edit/en.json');
    } else {
      return this.http.get('/assets/i18n/services/edit/es.json');
    }
  }

  loadLanguage(module: string): Observable<object> {
    const url = `assets/i18n/${module}/${this.ln.substring(0, 2)}.json`;
    return this.http.get(url);
  }

  loadPlanes() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/plans/en.json');
    } else {
      return this.http.get('/assets/i18n/plans/es.json');
    }
  }

  loadBrand() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/brand/en.json');
    } else {
      return this.http.get('/assets/i18n/brand/es.json');
    }
  }

  loadJoinUs() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/joinus/en.json');
    } else {
      return this.http.get('/assets/i18n/joinus/es.json');
    }
  }

  loadViewProfile() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/viewprofile/en.json');
    } else {
      return this.http.get('/assets/i18n/viewprofile/es.json');
    }
  }

  loadSearch() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/search/en.json');
    } else {
      return this.http.get('/assets/i18n/search/es.json');
    }
  }

  /* ======= SEARCH ENGINES ==========   */

  loadProfilesEngine() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/search-engine/profiles-search/en.json');
    } else {
      return this.http.get('/assets/i18n/search-engine/profiles-search/es.json');
    }
  }

  loadResourcesEngine() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/search-engine/resources-search/en.json');
    } else {
      return this.http.get('/assets/i18n/search-engine/resources-search/es.json');
    }
  }

  loadServicesEngine() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/search-engine/services-search/en.json');
    } else {
      return this.http.get('/assets/i18n/search-engine/services-search/es.json');
    }
  }

  /* ======= DOCS ========  */

  loadDocsIntroduction() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/docs/pages/introduction/en.json');
    } else {
      return this.http.get('/assets/i18n/docs/pages/introduction/es.json');
    }
  }

  /* === Januus Card */

  loadDocsActivate() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/docs/pages/januus-card/tutorials/activate/en.json');
    } else {
      return this.http.get('/assets/i18n/docs/pages/januus-card/tutorials/activate/es.json');
    }
  }

  loadDocsSharedProfile() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/docs/pages/januus-card/tutorials/shared-profile/en.json');
    } else {
      return this.http.get('/assets/i18n/docs/pages/januus-card/tutorials/shared-profile/es.json');
    }
  }

  /* === Januus App */

  loadDocsCreateProfile() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/docs/pages/januus-app/tutorials/create-profile/en.json');
    } else {
      return this.http.get('/assets/i18n/docs/pages/januus-app/tutorials/create-profile/es.json');
    }
  }

  loadDocsEditProfile() {
    if (this.ln === 'en') {
      return this.http.get('/assets/i18n/docs/pages/januus-app/tutorials/edit-profile/en.json');
    } else {
      return this.http.get('/assets/i18n/docs/pages/januus-app/tutorials/edit-profile/es.json');
    }
  }
}
