export const plansRoutes = {
  basic: 'basic',
  pro: 'pro',
  premium: 'premium',
  national: 'national',
  global: 'global',
};

export const loginRoutes = {
  email: 'email',
  forgot: 'forgot',
  register: 'register',
};

export const registerRoutes = {
  sendCode: 'send-code',
  newPassword: 'new-password',
};

export const myRoutes = {
  edit: 'edit',
};

export const paymentsRoutes = {
  reserve: 'reserve',
};

export const buyRoutes = {
  code: ':code',
};

export const searchRoutes = {
  profile: 'profile',
  profilesSearch: 'profiles-search',
  servicesSearch: 'services-search',
  resource: 'resource',
  resourcesSearch: 'resources-search',
};

export const aboutRoutes = {
  termsConditions: 'terms-conditions',
  terminosCondiciones: 'terminos-condiciones',
  privacyPolicy: 'privacy-policy',
  politicasPrivacidad: 'politicas-privacidad',
};

export const purchasesRoutes = {
  state: 'state',
};

export const pageRoutes = {
  ecosystem: 'ecosystem',
  januusCard: 'januus-card',
  basicSubscriptionOff: 'basic-subscription-off',
  multiLanguages: 'multi-languages',
  calendar: 'calendar',
  multiProfiles: 'multi-profiles',
  servicesAndFees: 'services-and-fees',
  analytics: 'analytics',
  paymentsSystem: 'payments-system',
  ambiental: 'ambiental',
  environmental: 'environmental',
  searchEngine: 'search-engine',
};

const appRoutes = {
  januusCommunity: 'januus-community',
  januusOfficial: 'januus-official',
  empty: '',
  notFound: '**',
  buy: 'buy',
  home: 'home',
  learnMore: 'learn-more',
  products: 'products',
  search: 'search',
  about: 'about',
  brand: 'brand',
  embajadoresDeMarca: 'embajadores-de-marca',
  joinUs: 'joinus',
  trabajaConNosotros: 'trabaja-con-nosotros',
  compras: 'compras',
  purchase: 'purchase',
  page: 'page',
  plans: 'plans',
  login: 'login',
  register: 'register',
  registerOptions: 'register-options',
  loginOptions: 'login-options',
  forgot: 'forgot',
  activate: 'activate',
  my: 'my',
  payments: 'payments',
  reservas: 'reservas',
  bookings: 'bookings',
  image: 'image',
  success: 'success',
  rankingAndRating: 'ranking-and-rating',
  virtualAgenda: 'virtual-agenda',
  workWithUs: 'work-with-us',
  plansRoutes,
};

export { appRoutes };
