import {Injectable} from '@angular/core';
import {AppViewTypes} from '@helpers/core/core.helpers';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WindowResizeService {
  private appViewTypeSubject = new BehaviorSubject<AppViewTypes>(AppViewTypes.Small);
  private innerWidthSubject = new BehaviorSubject<number>(0);
  private viewType;
  private innerWidth;

  constructor() {}

  onResize(innerWidth: number): void {
    this.innerWidth = innerWidth;
    if (innerWidth < 800) {
      this.viewType = AppViewTypes.Small;
    } else if (innerWidth >= 800 && innerWidth < 1400) {
      this.viewType = AppViewTypes.Standard;
    } else {
      this.viewType = AppViewTypes.Big;
    }
    this.innerWidthSubject.next(this.innerWidth);
    this.appViewTypeSubject.next(this.viewType);
  }

  get appViewType(): Observable<AppViewTypes> {
    return this.appViewTypeSubject.asObservable();
  }

  get appInnerWidth(): Observable<number> {
    return this.innerWidthSubject.asObservable();
  }
}
