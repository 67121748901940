// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  name: 'dev',
  production: false,
  baseUrl: 'https://test.januus.com',
  firebase: {
    apiKey: 'AIzaSyBa5lwDOtwYVgb7jOLgt7YIvoKJT6C25_o',
    authDomain: 'januus-82690.firebaseapp.com',
    projectId: 'januus-82690',
    storageBucket: 'januus-82690.appspot.com',
    messagingSenderId: '798978118081',
    appId: '1:798978118081:web:0638b052f9e421235d9f40',
    measurementId: 'G-FHPBS82QCF',
  },
  apiKey: 'ca028a58-b146-41ca-ab5b-65e29f1e0bab',
  apiKeyPay: 'e4dc80d1-fefc-4ab2-b741-ff0f265c01d7',
  urlGraphql: 'https://api-test.januus.com/graphql',
  urlPayments: 'https://payment-test.januus.com',
  urlFileUpload: 'https://escanor.mejorseguridad.com/archivo/subir',
};
