enum AppViewTypes {
  Small = 'small',
  Standard = 'standard',
  Big = 'big',
}

export {AppViewTypes};

export enum AccountTypes {
  INDIVIDUAL = '65e667270a1997a240b93dda',
  ENTERPRISE = '65e667610a1997a240b93ddb',
  EDUCATIONAL = '65e667970a1997a240b93ddc',
}

