import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
// if(environment.production){

//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/ngsw-worker.js')
//       .then(registration => console.log('Sw'))
//       .catch(error => console.log('Error al registrar el Service Worker:', error));
//   }

// }

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
